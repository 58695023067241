@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import '../../assets/styles/global/variables';
@import '~bootstrap/scss/mixins';
@import '../../assets/styles/global/mixins';

.full-width {
  &-image {
    &-wrapper {
      padding-bottom: 46.18%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @include media-breakpoint-up(lg) {
        background-attachment: fixed;
      }

      &--rounded {
        border-radius: 2.5rem;
        overflow: hidden;
        margin: 0 1rem;
        width: auto;
        padding-bottom: 25.8%;

        @include media-breakpoint-down(md) {
          border-radius: 1.25rem;
          padding-bottom: 56.25%;
        }
      }
    }
  }
}
